import React, { useContext } from 'react';
import Typography from "@mui/material/Typography";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";
import {TrendingUp} from "@mui/icons-material";
import OnlineSignupChartFilled from "./OnlineSignupChartFilled";
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const RipplePrice = (props) => {
    const {loginUserData} = useContext(CustomProvider);
    return (
        <JumboCardQuick
            title={<Typography variant={"h3"} color={"common.white"}>{(loginUserData.totalStockistBonus || 0).toLocaleString('en-NG', {style: 'currency',currency: 'NGN',})}</Typography>}
            subheader={
                <Typography
                    variant={"h6"}
                    color={"common.white"}
                    mb={0}
                >{props.title}</Typography>
            }
            action={
                <Link to="/stockist-bonus">
                <Button color='warning' size='small' variant="contained">
                  View Report
                </Button>
                 </Link>
            }
            sx={{color: "common.white"}}
            bgColor={"#E44A77"}
            wrapperSx={{pt: 0}}
        >
            <OnlineSignupChartFilled color={"#fff"} shadowColor={"#fff"}/>
        </JumboCardQuick>
    );
};

export default RipplePrice;
