import { Box, Button, Paper,CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Tooltip, Chip } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {  postRequest } from 'backendServices/ApiCalls';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Div from "@jumbo/shared/Div";
import React, { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {  CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import { format } from 'date-fns';

const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
        '& .MuiButton-text': {
            fontSize: '13px !important',
            color: '#000',
        },
        '& .MuiBadge-badge': {
            backgroundColor: '#074682',
        },
        '& .MuiInput-root': {
            borderRadius: 2,
            paddingLeft: 2,
            overflow: 'hidden',
        },
    },
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};




const PendingOrder = () => {
    const [orderData, setOrderData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [alertopen, setAlertOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [selectedorderdata, setSelectedOrderData] = useState([]);
    const [imageurl, setImageUrl] = useState('https://nodeapp.m5networkhub.com/uploads/products/');
    const [loadingStates, setLoadingStates] = useState({});

 const Swal = useSwalWrapper();
 
const sweetAlerts = (orderId) => {
    Swal.fire({
        title: 'Are you sure to update Status?',
        text: "You are going apporve status ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Approve!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
    }).then(result => {
        if (result.value) { 
            handleOrderStatus(orderId)
        } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
        ) {
            Swal.fire('Cancelled', 'Ok with Pending :)', 'error');
        }
    });
};



  
    const OrderData = () => {
        let params = {
            status: 'pending',
        };
        postRequest(
            '/getstockistproductorders',
            params,
            (response) => {
                if (response?.data?.status === 'success') {
                    setOrderData(response?.data?.data);
                }
                
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    };


    const handleOrderDetails = (id) => {
        setLoadingStates((prevState) => ({ ...prevState, [id]: true }));
        let params = {
            orderid: id
        }
        postRequest(
            '/getorderdetails',
            params,
            (response) => {
                if (response?.data?.status === 'success') {
                    setSelectedOrderData(response?.data?.data);
                    setOpen(true)
                }
                setLoadingStates((prevState) => ({ ...prevState, [id]: false }));
            },
            (error) => {
                console.log(error?.response?.data);
            }
            
        );
};
   

    const handleOrderStatus = (id ) => {
        let params = {
            orderstatus: 'approved',
            packageid : id,
        }
        postRequest(
            '/approvestockistproductorder',
            params,
            async (response) => {
                if (response?.data?.status === 'success') {
                  await Swal.fire('Approved!', response?.data?.message, 'success');
                    await setAlertOpen(true)
                    const nPayoutData = await orderData.filter((pdata)=>pdata.id !== id)
                    await setOrderData(nPayoutData);
                  }
            },
            (error) => {
                console.log(error?.response?.data);
            }
        );
    }


  const subtotal = selectedorderdata.reduce((total, item) => total + item.price * item.quantity, 0);
        

    const columns = [

        {
            field: 'id',
            headerName: 'Sr #',
            width: 50,
        },
        {
            field: "buyername",
            headerName: "Buyer",  
            dataGeneratorUniquenessEnabled: true,
            width: 150,
            editable: false,
            groupable: false,
            aggregable: false,
            aggregable: false,
        
        },
        {
            field: 'orderid',
            headerName: 'Order ID',
            width: 150,
        },
        {
            field: 'amount',
            headerName: 'Order Amount',
            width: 150,
            renderCell: (params) => `N${params.value}`
        },
        // {
        //     field: 'shipping_charges',
        //     headerName: 'Shipping Charges',
        //     width: 150,
        //     renderCell: (params) => `N${params.value}`

        // },
        {
            field: 'aa',
            headerName: 'Order Details',
            renderCell: (params) => (
                <div>
                    {/* Button to open the modal */}
                    <Button variant="outlined" disabled={loadingStates[params.row.orderid]} size='small' color="primary" onClick={() => handleOrderDetails(params.row.orderid)}>
                    {loadingStates[params.row.orderid] ? (
              <CircularProgress size={24} />
            ) : (
              'Details'
            )}
                    </Button>
                    
                </div>
            ),
            width: 150,
        },
        {
            field: "status",
            headerName: "Status",  
            dataGeneratorUniquenessEnabled: true,
            width: 100,
            editable: false,
            groupable: false,
            aggregable: false,  
            renderCell : (params) => {
             return  params.value === 'pending' ? (<Chip color='warning' label='Pending' />)
              :
              params.value === 'rejected' ? (<Chip color='error' size='small' label='Rejected' />)
              :
              (<Chip color='success' size='small' label='Approved' />)
        
            }
        
        
        },
          
            {
                field: "storename",
                headerName: "Stockist",  
                dataGeneratorUniquenessEnabled: true,
                width: 150,
                editable: false,
                groupable: false,
                aggregable: false, 
        
            },
            {
              field: "createdat",
              headerName: "Requested On",  
              dataGeneratorUniquenessEnabled: true,
              width: 200,
              renderCell : (params) => { 
                  return format(new Date(params.value), 'dd-MM-yyyy');
              } 
          
          },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <div>
                    {/* Button to open the modal */}
                    <Tooltip title="Approve Order">
                        <CheckCircleIcon sx={{cursor: 'pointer'}} open={alertopen} color='success'  onClick={() => sweetAlerts(params.row.id)}/>
                    </Tooltip>
                    {/* <HighlightOffRoundedIcon sx={{cursor: 'pointer'}} color='error' onClick={() => RejectAlert(params.row.orderid , params.row.rejectionreason)}  /> */}
                </div>
            ),
        },


    ];
    
    useEffect(() => {
        OrderData();
    }, []);

    return (
        <JumboDemoCard
        title={"Pending Product Orders"}
        wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
   
    <Box sx={{ height: 400, width: 1 }}>
                <DataGrid
                    initialState={{
                        initialState: {
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    avatar: false,
                                    website: false,
                                    email: false,
                                    phone: false,
                                    username: false,
                                    city: false,
                                    company: false,
                                    position: false,
                                    lastUpdated: false,
                                    salary: false,
                                },
                            },
                        },
                        pagination: { paginationModel: { pageSize: 6 } },
                    }}
                    rows={orderData}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    sx={gridDesign}
                    pageSizeOptions={[6, 12, 18, 24, 30]}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>



            {/* Modal */}
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ minWidth: 350 }}
            >
                <Div sx={style}>
                    <Typography id="modal-modal-title" variant="h4" 
                        component="h2"
                        display='flex' 
                        justifyContent='space-between'>
                        Order Details
                        <Close style={{cursor: 'pointer'}}  onClick={handleClose}/>
                    </Typography>
                    
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <TableContainer component={Paper} sx={{ overflowY : 'scroll', height: "auto"}}>
                    <Table sx={{ minWidth: 350  }}>
                      <TableHead style={{ fontWeight: 'bolder' }}>
                        <TableRow >
                          <TableCell>Product Image</TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {selectedorderdata.map((item) => (
                          <>
                            <TableRow key={item.id}>
                              <TableCell>
                                <img src={`${imageurl}${item.picture}`} alt={item.title} height="40"
                                  style={{ borderRadius: "50%", width: '40px' }} />

                              </TableCell>
                              <TableCell > {item.title}</TableCell>
                              <TableCell>N{item.price}</TableCell>
                              <TableCell>{item.quantity}</TableCell>
                            </TableRow>

                          
                       

                        
                        </>
                        
                        ))}
                        <TableCell
                        align="right" colSpan={4}>
                        
                             Subtotal: N{subtotal}
                        </TableCell>
                      </TableBody>
                      
                    </Table>
                  </TableContainer>
                    </Typography>
                </Div>
            </Modal>
            

            
            
        
            
     
            </JumboDemoCard>
    )
}

export default PendingOrder
