import React, { useState, useEffect } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import { Alert, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div/Div";
import { postRequest } from "backendServices/ApiCalls";
import { useContext } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";

const Payout = () => {
  const { t } = useTranslation();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [settingsdata, setSettingsData] = useState([]);
  const userData = loginUserData;

  const fetchsettingdata = () => {
    const params = {
      keynames: "'payout_fee', 'min_payout', 'payout_flat_fee'",
    };
    postRequest("/getsettingsdata", params, (response) => {
      setSettingsData(response?.data?.data);
    });
  };

  useEffect(() => {
    fetchsettingdata();
  }, []);

  const validationSchema = yup.object({
    amount: yup
      .number("Enter investment amount")
      .required("Amount is required")
      .min(
        settingsdata?.values[1]?.keyvalue,
        `Amount must be at least ${settingsdata?.values[1]?.keyvalue}`
      ),
    payoutaccount1: yup
      .string("Enter Withdrawal Method")
      .required("Withdrawal Method is required"),
    payoutaccount2: yup
      .string("Enter Wallet Details")
      .required("Wallet details is required"),
  });

  const onSubmitForm = (
    amount,
    payoutaccount1,
    payoutaccount2,
    password,
    setSubmitting,
    resetForm
  ) => {
    let params = {
      amount,
      payoutaccount1,
      payoutaccount2,
      type: "payout",
      status: "Pending",
      details: "Request payout of $" + amount,
      password,
      payouttype:"balance"
    };

    postRequest(
      "/payoutrequest",
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          let netamount = userData.current_balance - amount;
          setloginUserData((prevState) => ({
            ...prevState,
            current_balance: netamount,
          }));

          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setloginUserData((prevState) => ({
            ...prevState,
          }));
          setSubmitting(false);
          resetForm();
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const currentDate = new Date().getDate();

  // Check if the current date is 16 or 17
  const isAllowedDate = currentDate >0;

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Grid item sm={5}>
        <JumboDemoCard
          title={"Request Team Income Withdrawal"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {isAllowedDate ? (
            <>
              <Grid container>
                <Grid item sx={12} md={12} lg={12}>
                  <Alert sx={{ color: "white" }} severity="error" variant="filled">
                    Your request will be release within 24 hours. Thank You!
                  </Alert>
                </Grid>

                <Grid item sx={12} md={12} lg={12}>
                  <Formik
                    enableReinitialize="true"
                    validateOnChange={true}
                    initialValues={{
                      amount: "",
                      payoutaccount1: "USDT (TRC 20)",
                      payoutaccount2: "",
                      random: loginUserData.walletbalance || "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      onSubmitForm(
                        data.amount,
                        data.payoutaccount1,
                        data.payoutaccount2,
                        data.password,
                        setSubmitting,
                        resetForm
                      );
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form
                        style={{ textAlign: "left" }}
                        noValidate
                        autoComplete="off"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            "& .MuiTextField-root": { width: "34ch" },
                          }}
                          alignItems="center"
                        >
                          <Div sx={{ mt: 2 }}>
                            <JumboTextField
                              fullWidth
                              name="current_balance"
                              label={"Your Balance is $" + userData.current_balance}
                              type="number"
                              disabled
                            />
                          </Div>
                          <Div sx={{ mt: 3 }}>
                            <JumboTextField
                              fullWidth
                              name="amount"
                              label="Enter Payout Amount"
                              type="number"
                            />
                          </Div>
                          <Div sx={{ mt: 3 }}>
                            <JumboTextField
                              fullWidth
                              name="payoutaccount1"
                              label="Enter Withdrawal Method"
                              value={"USDT (TRC 20)"}
                              type="text"
                              disabled
                            />
                          </Div>
                          <Div sx={{ mt: 3, mb: 2 }}>
                            <JumboTextField
                              fullWidth
                              name="payoutaccount2"
                              label="Enter Wallet Details"
                              type="text"
                            />
                          </Div>
                          {/* <Div sx={{mt: 3,mb:2}}>
                                        <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Enter Password"
                                        type="password"
                                    /></Div> */}

                          <LoadingButton
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mb: 3 }}
                            loading={isSubmitting}
                          >
                            Submit Request
                          </LoadingButton>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>

            </>
          ) : (
            <Alert sx={{ color: "white" }} severity="error" variant="filled">
              You can only request withdrawals at 16th and 17th of the each month. Thank You!
            </Alert>
          )}
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Payout;
