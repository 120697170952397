import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { RankReportApi, postRequest } from "backendServices/ApiCalls";
import { Grid, TableCell } from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";

function Rank() {
  const [rankReport, setRankReport] = useState([]);

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const RankReport = () => {
    postRequest(
      "/getrankreport",
      '',
      (response) => {
        if (response?.data?.status === "success") {
          setRankReport(response?.data?.data);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    RankReport();
  }, []);
  const rowsWithIndex = rankReport?.map((row, index) => ({
    ...row,
    id: index + 1,
  }));

  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
      valueGetter: (params) => params.row.id,
    },
    {
      field: "old_rank_name",
      headerName: "Old Rank Name",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "new_rank_name",
      headerName: "New Rank Name",
      width: 200,
      editable: true,
      groupable: false,
    },
    {
      field: "dat",
      headerName: "Date",
      width: 200,
      editable: true,
      groupable: false,
    },
  ];

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#000",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Rank Report"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}

          <Box sx={{ height: 500, width: 1 }}>
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={rowsWithIndex}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
}

export default Rank;
