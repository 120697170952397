import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import {  postRequest } from 'backendServices/ApiCalls';
import { Chip, IconButton, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { format } from 'date-fns';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const initialState= {initialState:{
  columns:{
      columnVisibilityModel:{
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
    }
}
}
}


const ApprovedPackages = () => {

  const [payoutdata,setPayoutData]=useState([])
  const [alertopen, setAlertOpen] = React.useState(false);


  const Swal = useSwalWrapper();
 
  const approveOrder = (orderId) => {
      Swal.fire({
          title: 'Are you sure to update Status?',
          text: "You are going apporve status ",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, Approve!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true,
      }).then(result => {
          if (result.value) { 
              handleOrderStatus(orderId)
          } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
          ) {
              Swal.fire('Cancelled', 'package is not approved', 'error');
          }
      });
  };

  const PayoutData =()=>{
    postRequest('/getstockistpackages',{type:'approved'},(response) => {
      if(response?.data?.status === "success") {
          setPayoutData(response?.data?.data)
      }
      }, (error) => {
          console.log(error?.response?.data); 
      })
  }
  console.log("payoutdata",payoutdata)
    
useEffect(()=>{
    PayoutData();
},[])


const handleOrderStatus = (id) => {
  let params = {
      orderstatus: 'delivered',
      packageid : id,
  }
  postRequest(
      '/deliverstockistpackage',
      params,
      async (response) => {
          if (response?.data?.status === 'success') {
            await Swal.fire('Approved!', response?.data?.message, 'success');
              await setAlertOpen(true)
              const nPayoutData = await payoutdata.filter((pdata)=>pdata.id !== id)
              await setPayoutData(nPayoutData);
            }
      },
      (error) => {
          console.log(error?.response?.data);
      }
  );
}


const columns = [
  {
    field: "buyername",
    headerName: "Buyer",  
    dataGeneratorUniquenessEnabled: true,
    width: 150,
    editable: false,
    groupable: false,
    aggregable: false,
    aggregable: false,

},
    {
      field: "packagename",
      headerName: "Package",  
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
      aggregable: false,

  },
  {
    field: "amount",
    headerName: "Amount",  
    dataGeneratorUniquenessEnabled: true,
    width: 150,
    editable: false,
    groupable: false,
    aggregable: false,
    aggregable: false,
    renderCell: (params) => {
      return `N${params.value}`;
    }
  
  },
  {
    field: "status",
    headerName: "Status",  
    dataGeneratorUniquenessEnabled: true,
    width: 100,
    editable: false,
    groupable: false,
    aggregable: false,  
    renderCell : (params) => {
     return  params.value === 'pending' ? (<Chip color='warning' label='Pending' />)
      :
      params.value === 'rejected' ? (<Chip color='error' label='Rejected' />)
      :
      (<Chip color='success' size='small' label='Approved' />)

    }


},
  
    {
        field: "storename",
        headerName: "Stockist",  
        dataGeneratorUniquenessEnabled: true,
        width: 150,
        editable: false,
        groupable: false,
        aggregable: false, 

    },
    {
      field: "createdat",
      headerName: "Requested On",  
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      renderCell : (params) => { 
          return format(new Date(params.value), 'dd-MM-yyyy');
      } 
  
  },
  {
    field: "updatedat",
    headerName: "Approve Date",  
    dataGeneratorUniquenessEnabled: true,
    width: 200,
    renderCell : (params) => { 
      return format(new Date(params.value), 'dd-MM-yyyy');
  } 

},

{
  field: 'action',
  headerName: 'Action',
  width: 150,
  renderCell: (params) => (
      <div>
          {/* Button to open the modal */}
          <Tooltip title="Deliver Package">
            <CheckCircleIcon sx={{cursor: 'pointer'}} open={alertopen} color='success'  onClick={() => approveOrder(params.row.id)}/>
          </Tooltip>
          {/* <HighlightOffRoundedIcon sx={{cursor: 'pointer'}} color='error' onClick={() => rejectOrder(params.row.orderid , params.row.rejectionreason)}  /> */}
      </div>
  ),
},


  ]

const rows= payoutdata
const gridDesign = {
  '& .MuiDataGrid-toolbarContainer': {
    '& .MuiButton-text': {
      fontSize: '13px !important',
                color: '#f5343e',
    },
    '& .MuiBadge-badge': {
      backgroundColor: '#074682',
    },
    '& .MuiInput-root':{
      borderRadius: 2,
      paddingLeft: 2,
      overflow: 'hidden',
    },

  }
}

  return (
    <JumboDemoCard
    title={"Approved Packages List"}
    wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
>

    <Box sx={{ height: 400, width: 1 }}>
    <DataGrid
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 6 } },
        }}
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        sx={gridDesign}
        pageSizeOptions={[6, 12, 18, 24, 30]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  </JumboDemoCard>
  )
}

export default ApprovedPackages