import React, { useState, useEffect, useContext } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  Grid,
  IconButton,
  Alert,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { Form, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div/Div";
import { postRequest, transaction } from "backendServices/ApiCalls";
import { CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InputAdornment from "@mui/material/InputAdornment";
import { Gr } from "react-flags-select";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

const validationSchema = yup.object({
  amount: yup.number().required("Amount is required"),
  paymentproof: yup
    .string()
    .required("Screenshot of payment proof is required"),
  password: yup.string().required('Password is required')
});

const initialstates = {
  amount: "",
  transactionid: "",
  password:"",
};

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const Deposit = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [adminwalletdata, setAdminWalletData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  let userData = loginUserData;

  const WalletData = () => {
    let params = {
      keynames: "'depositwallet'",
    };
    postRequest(
      "/getadminwallet",
      params,
      (response) => {
        setLoading(true);
        setAdminWalletData(response?.data?.data?.entries);
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      }
    );
  };

  const handleSelectChange = (event) => {
    setSelectedOption("");
    setLoading(true);
    const coinid = event.target.value;
    let params = {
      tid: coinid,
    };
    postRequest(
      "/getsingledepositwallet",
      params,
      (response) => {
        setSelectedOption(response?.data?.data);
        setLoading(false);
      },
      (error) => {
        console.log(error?.response?.data);
        setLoading(false);
      }
    );

    //setSelectedOption();
    // Call your function here
  };

  const handleUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        const base64Image = await convertBase64(selectedFile);
        await setImage(base64Image);
      } else {
        await setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
      }
    }
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    if (!image) {
      setalertData({
        show: true,
        message:
          "Invalid file. Please select a screenshot image for payment proof.",
        variant: "error",
      });
      setSubmitting(false);
      return;
    }
    if(selectedOption === "")
    {
      setalertData({
        show: true,
        message:
          "Please select a deposit wallet first",
        variant: "error",
      });
      setSubmitting(false);
      return;
    }

    let params = {
      payoutaccount2:image,
      amount:data.amount,
      hash:data?.transactionid,
      type: 'deposit',
      status: 'pending',
      details: `deposit an amount of ${data.amount} `,
      password:data?.password,
      payoutmethod:selectedOption?.entries[0]?.coinname,
      payoutaccount1:selectedOption?.entries[0]?.walletaddress
  }
    postRequest(
      "/transaction",
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setloginUserData((prevState) => ({
            ...prevState,
            paymentstatus: "submitted",
          }));
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
          resetForm();
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        setSubmitting(false);

        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    WalletData();
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      selectedOption?.entries[0]?.walletaddress || ""
    );
    setIsCopied(true);

    // Reset the state after a certain duration if needed
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    // setalertData({
    //   show: true,
    //   message: "address copied to clipboard",
    //   variant: "success",
    // });
  };

  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Grid item sm={5}>
        <JumboDemoCard
          title={"Deposit Account"}
          wrapperSx={{
            backgroundColor: "background.paper",
            pt: 0,
            minHeight: "390px",
            display: "block",
          }}
        >
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <InputLabel id="select-label">Select deposit method</InputLabel>
              <Select
                labelId="select-label"
                value={selectedOption.tid}
                fullWidth
                onChange={handleSelectChange}
              >
                {adminwalletdata.map((option) => (
                  <MenuItem key={option.value} value={option.tid}>
                    {option.coinname}
                  </MenuItem>
                ))}

                {/* Add more options if needed */}
              </Select>
            </Grid>
          </Grid>

          {loading ? (
            <Grid
              xs={12}
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                height: "100%",
                marginTop: "50px",
              }}
            >
              <CircularProgress sx={{ m: "-40px auto 0" }} />
            </Grid>
          ) : null}

          {selectedOption ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              alignItems="center"
            >
              <TextField
                type="text"
                value={selectedOption?.entries[0]?.walletaddress}
                margin="normal"
                label="Deposit Account"
                fullWidth
                InputProps={{
                  readOnly:true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopy}>
                        {isCopied ? (
                          <CheckCircleOutlineIcon />
                        ) : (
                          <ContentCopy />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Alert severity="info">
                {selectedOption?.entries[0]?.walletmessage}
              </Alert>
              <Box
                component="img"
                sx={{
                  marginTop: 1,
                  height: 155,
                  width: 155,
                  backgroundColor: "white",
                }}
                alt="The house from the offer."
                src={
                  selectedOption.picturelink +
                  selectedOption?.entries[0]?.walletqrcode
                }
              />

            </Box>
          ) : null}
        </JumboDemoCard>
      </Grid>
      <Grid item sm={6}>
        <JumboDemoCard
          title={"Enter Deposit Details"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>

                  <Div sx={{ mt: 2, mb: 4 }}>
                    <Alert severity="warning">
                      Enter correct details otherwise your deposit can be rejected
                    </Alert>
                  </Div>

                  <Formik
                    validateOnChange={false}
                    enableReinitialize="true"
                    initialValues={initialstates}
                    validationSchema={validationSchema}
                    onSubmit={(data, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      handleSubmit(data, setSubmitting, resetForm);
                    }}
                  >
                    {({ isSubmitting, errors, setFieldValue }) => (
                      <Form
                        style={{ textAlign: "left" }}
                        noValidate
                        autoComplete="off"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <JumboTextField
                              fullWidth
                              name="amount"
                              label="Amount(N)"
                              type="number"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              style={{ marginTop: "0" }}
                              onChange={(e) => {
                                setFieldValue(
                                  "paymentproof",
                                  e.target.files[0]
                                );
                                handleUpload(e);
                              }}
                              name="paymentproof"
                              type="file"
                              label="Payment Proof"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <CameraAltIcon
                                    fontSize={"small"}
                                    color={"success"}
                                  />
                                ),
                              }}
                              error={Boolean(errors.paymentproof)}
                              helperText={errors.paymentproof}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <JumboTextField
                              fullWidth
                              name="transactionid"
                              label="Transaction Number (optional)"
                              type="text"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <JumboTextField
                              fullWidth
                              name="password"
                              label="Account Password"
                              type="password"
                            />
                          </Grid>

                          <Grid item sm={12}>
                            <LoadingButton
                              fullWidth
                              type="submit"
                              variant="contained"
                              size="large"
                              sx={{ mb: 3 }}
                              loading={isSubmitting}
                            >
                              Submit
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>

            </Grid>
          </Grid>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Deposit;