import React, { useState, useEffect } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {Form, Formik} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div/Div';
import {postRequest } from 'backendServices/ApiCalls';
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';

const BalanceTransfer = () => {
  const [alertData, setalertData] = React.useState({
    show:false,
    message:"",
    variant:"" 
  })
  
  const fromWallets = [
    { key: 'deposit_balance', label: 'Purchasing Wallet' },
    { key: 'current_balance', label: 'Bonus Wallet' },
    { key: 'stockist_balance', label: 'Stockist Wallet' },
  ];

  
  const {loginUserData,setloginUserData} = useContext(CustomProvider);
  const [settingsdata,setSettingsData] = useState([]);
  const [toWallets,setToWallets] = useState([]);
  const [selectedFromWallet,setSelectedFromWallet] = useState('');
  const [selectedToWallet,setSelectedToWallet] = useState('');
  const [availableBalance,setAvailableBalance] = useState(0);
  const userData = loginUserData

  const fetchsettingdata = () => {
    const params = {
      keynames: "'payout_fee', 'min_payout', 'payout_flat_fee'"
    };
    postRequest('/getsettingsdata',params, (response) => {
      setSettingsData(response?.data?.data);
    });
  };

  useEffect(()=>{
    fetchsettingdata();
  },[])

  const handleFromWallet = async (fromwallet) =>{
        // Use the filter method to get objects with the specified key
        let walletBalance
        const filteredWallets = await fromWallets.filter(wallet => wallet.key !== fromwallet);
        await setToWallets(filteredWallets)
        if(fromwallet === "deposit_balance")
        {
            walletBalance = loginUserData?.accountbalance
        }else{
          walletBalance = loginUserData[fromwallet]
        }
        await setAvailableBalance(walletBalance)
        await setSelectedToWallet('')

  }
  const validationSchema = yup.object({
    fromwallet: yup
        .string('Select a from wallet')
        .required('wallet is required'),
    towallet: yup
    .string('Select a to wallet')
    .required('wallet is required'),
    amount: yup
    .number('Enter correct amount')
    .required('Amount is required'),
    password: yup
    .string('Enter Account Password')
    .required('Password is required'),
  });


  const onSubmitForm = (data,setSubmitting,resetForm) => {
    let params = {
      amount:data.amount,
      fromwallet:data.fromwallet,
      towallet:data.towallet,
      password: data.password,

  }
 

    postRequest('/balancetransfer',params, async (response) => {
      if( response?.data?.status === "error"){
        await setalertData({
          show:true,
          message:response?.data?.message,
          variant:"error"
      }) 
      }else if(response?.data?.status === "success"){
        let netamount=userData[data.fromwallet]-data.amount;
        let plusamount=userData[data.towallet]+data.amount;
        await setAvailableBalance('')
        await setloginUserData((prevState) => ({
          ...prevState,
          [data.fromwallet]: netamount,
          [data.towallet]: plusamount,
        }));

        await setalertData({
          show:true,
          message:response?.data?.message,
          variant:"success"
      })
      await resetForm()
      await setSelectedFromWallet('')
      await setSelectedToWallet('')
      }

      await setSubmitting(false)

  }, (error) => {
      console.log(error?.response?.data);
  })
}


    return (
      <Grid container spacing={2}  alignItems="center" justifyContent="center" >
         {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
        }
     
      <Grid item sm={5} >
        <JumboDemoCard  title={'Transfer Balance'}
                       wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
             <Formik
             enableReinitialize="true"
                        validateOnChange={true}
                        initialValues={{
                            fromwalletbalance: '',
                            towallet: '',
                            amount:'',
                            availablebalance: availableBalance || 0,
                        }}
                        
                        validationSchema={validationSchema}
                         onSubmit={(data, { setSubmitting, resetForm }) => {
                          console.log('ddddd',data?.fromwallet)
              setSubmitting(true);
              onSubmitForm(data,setSubmitting, resetForm);
            }}
                    >
                        {({isSubmitting,values,setFieldValue}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>

<Grid container spacing={2}>

    <Grid item sm={12}>
    <FormControl variant="outlined" fullWidth>
        <InputLabel>Select From Wallet</InputLabel>
        <Select
          label="Select an option"
          name="fromwallet"
          value={selectedFromWallet}
          onChange={
            async (e)=>{
                await handleFromWallet(e.target.value)
                await setSelectedFromWallet(e.target.value)
                setFieldValue('fromwallet',e.target.value)

            }
        }
        //   onBlur={formik.handleBlur}
        >
            {fromWallets.map((option, index) => (
            loginUserData?.stockist === 'disabled' && option.key === 'stockist_balance' ? null : (
                <MenuItem key={index} value={option.key}>
                {option.label}
                </MenuItem>
            )
            ))}
        </Select>
      </FormControl>
      </Grid>
      <Grid item sm={12}>

      <FormControl variant="outlined" fullWidth>
        <InputLabel>Select To Wallet</InputLabel>
        <Select
          label="Select an option"
          name="towallet"
          value={selectedToWallet}
          onChange={
            (e)=>{
              setFieldValue('towallet',e.target.value)
                setSelectedToWallet(e.target.value)
            }
        }
        //   onBlur={formik.handleBlur}
        >
            {toWallets.map((option, index) => (
            loginUserData?.stockist === 'disabled' && option.key === 'stockist_balance' ? null : (
                <MenuItem key={index} value={option.key}>
                {option.label}
                </MenuItem>
            )
            ))}
        </Select>
      </FormControl>
                                  
      </Grid>
      <Grid item sm={12}>

                                    <JumboTextField
                                        fullWidth
                                        name="availablebalance"
                                        label={'Available Wallet Balance'}
                                        type="number"
                                        inputPropes={{ readOnly: true }}

                                    /></Grid>
      <Grid item sm={12}>

                                    <JumboTextField
                                        fullWidth
                                        name="amount"
                                        label="Enter Amount To Transfer"
                                        type="number"
                                    />      </Grid>
                           
                           <Grid item sm={12}>
                                        <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Enter Account Password"
                                        type="password"
                                    /></Grid>

   



      
               
      <Grid item sm={12}>

              
<LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >Submit Request</LoadingButton>

</Grid>
</Grid>

            </Form>
             )}
             </Formik>
        </JumboDemoCard>
      </Grid>
      </Grid>
    );
};

export default BalanceTransfer;